import $ from "jquery";

$(window).on("resize", function () {
  registerOverflow();
});

export function registerOverflow() {
  if (window.location.pathname.split("/")[1] === "register") {
    const container = document.getElementById("overflow-register");
    if (container.scrollHeight > container.clientHeight) {
      $("#overflow-register").css({ justifyContent: "flex-start" });
    } else {
      $("#overflow-register").css({ justifyContent: "center" });
    }
  }
}

// Gecici
$(document).scroll(function () {
  var x = $(document).scrollTop();
  if (x >= 500) {
    $("nav").addClass("fix");
  } else {
    $("nav").removeClass("fix");
  }
});
