import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Nav extends Component {
  clickPage(hash) {
    if (hash) {
      const targetElement = document.getElementById(hash.substring(1));
      targetElement?.scrollIntoView({ behavior: "smooth" });
    }
  }

  render() {
    return (
      <nav className="">
        <Link
          to={{ pathname: "/", hash: "#homepage" }}
          onClick={() => this.clickPage("#homepage")}
        >
          <figure>
            <img src="/img/1m1.svg" alt="" />
          </figure>
        </Link>
        <div className="pages">
          <Link
            to={{ pathname: "/", hash: "#homepage" }}
            className="h-8"
            onClick={() => this.clickPage("#homepage")}
          >
            Anasayfa
          </Link>
          <Link
            to={{ pathname: "/", hash: "#rewards" }}
            className="h-8"
            onClick={() => this.clickPage("#rewards")}
          >
            Ödüller
          </Link>
          <Link
            to={{ pathname: "/", hash: "#memberships" }}
            className="h-8"
            onClick={() => this.clickPage("#memberships")}
          >
            Üyelikler
          </Link>
          <Link
            to={{ pathname: "/", hash: "#how-to" }}
            className="h-8"
            onClick={() => this.clickPage("#how-to")}
          >
            Nasıl Oynanır?
          </Link>
        </div>
      </nav>
    );
  }
}
