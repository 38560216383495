import * as actionTypes from "./actionTypes";
import initialState from "../reducers/initialState";
import alertify from "alertifyjs";

export function getCouponsSuccess(coupons) {
  return {
    type: actionTypes.GET_COUPONS_SUCCESS,
    payload: coupons,
  };
}

export function getCoupons(token) {
  return async function (dispatch) {
    let url = initialState.url + "/coupons/all-coupons";
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      dispatch(getCouponsSuccess(result));
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}
