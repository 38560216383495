import React, { Component } from "react";
import { Link } from "react-router-dom";
import VerifyCodeInput from "../verifyCode/VerifyCodeInput";

export default class VerifyCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeLeft: 10, // Başlangıç süresi: 5 dakika (300 saniye)
    };
  }

  componentDidMount() {
    // Zamanlayıcıyı başlat
    this.timerId = setInterval(() => {
      this.setState((prevState) => ({
        timeLeft: prevState.timeLeft - 1,
      }));
    }, 1000);
  }

  componentDidUpdate() {
    // Geri sayım 0'a ulaştığında zamanlayıcıyı durdur
    if (this.state.timeLeft <= 0 && this.timerId) {
      clearInterval(this.timerId);
    }
  }

  componentWillUnmount() {
    // Zamanlayıcıyı bileşen unmount edildiğinde durdur
    clearInterval(this.timerId);
  }

  render() {
    // Dakika ve saniyeye dönüştürme
    const minutes = Math.floor(this.state.timeLeft / 60);
    const seconds = this.state.timeLeft % 60;

    return (
      <div className="verify-code-page">
        <VerifyCodeInput />
        <div className="content-verify">
          <div className="content-verify-inner phone">
            <p className="b-1">
              Telefon numaranızı doğrulamanız, hesabınızın güvenliğini artırmak
              ve kişisel bilgilerinizi korumak için önemli bir adımdır. Bu
              sayede, sadece size özel erişim sağlanır ve yetkisiz girişler
              engellenir.
            </p>
            <div className="links">
              <Link to=".." className="b-2">
                Telefon Numarasını Değiştir
              </Link>
              <Link className="b-2">Tekrar Mesaj Gönder</Link>
            </div>
          </div>
          <p className="counter b-2">
            Kalan Süre: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </p>
        </div>
        <button
          className="background-btn"
          disabled={this.state.timeLeft <= 0 ? true : false}
        >
          <h4 className="h-8">Devam et</h4>
        </button>
      </div>
    );
  }
}
