import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as matchActions from "../../redux/actions/matchActions";

class LeagueList extends Component {
  componentDidMount() {
    this.props.actions.getLeagueList();
  }

  leagueTeams(id) {
    this.props.actions.getLeagueMatch(id);
  }

  render() {
    return (
      <div>
        <h1>League List</h1>
        {this.props.leagueList.map((league) => (
          <div key={league.id}>
            <Link
              to={"teams?id=" + league.name}
              onClick={() => this.leagueTeams(league.id)}
            >
              {league.name}
            </Link>
            <br />
          </div>
        ))}
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getLeagueList: bindActionCreators(matchActions.getLeagueList, dispatch),
      getLeagueMatch: bindActionCreators(matchActions.getLeagueTeams, dispatch),
    },
  };
}

function mapStateToProps(state) {
  return {
    leagueList: state.leagueListReducer,
    teamList: state.teamsListReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeagueList);
