export default {
  url: "http://88.247.145.163:8000",
  isActive: true,
  leagueList: [],
  teamsList: [],
  matches: [],
  match: [],
  userDetails: [],
  coupons: [],
  selectedLeague: {
    id: 0,
    name: "Favori Liginizi Seçiniz",
    country: "1M1 Ligi",
    logo: "logo/logo_1_ZWlfaMP.jpg",
    flag: "flag/flag_1_rqwXPHY.svg",
  },
  selectedTeam: {
    id: 0,
    name: "Favori Takımınız",
    code: "1M1 Takımı",
    country: "",
    logo: "logo/logo_1_ZWlfaMP.jpg",
    last_5_matches: "",
  },
  selectedPhoneCode: {
    flag: "/img/country/Flag_of_Turkey.svg",
    name: "Türkiye",
    dial_code: "+90",
    code: "TR",
  },
  register: {
    phone: "",
    mail: "",
    username: "",
    password: "",
    password_again: "",
    use_agreement: "",
    campaign_notice: "",
  },
  phoneCode: {},
  canBeUsed: {
    phone: true,
    email: true,
    username: true,
  },
  token: "",
};
