import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as matchActions from "../../redux/actions/matchActions";

class Teams extends Component {
  componentDidMount() {
    if (window.location.pathname === "/teams") {
      this.props.actions.getTeams();
    }
  }
  render() {
    return (
      <div>
        <h1>Teams</h1>
        {this.props.teamsList.map((team) => (
          <p key={team.id}>{team.name}</p>
        ))}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getTeams: bindActionCreators(matchActions.getTeam, dispatch),
    },
  };
}

function mapStateToProps(state) {
  return {
    teamsList: state.teamsListReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
