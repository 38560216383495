import { Route, Routes } from "react-router-dom";
import Homepage from "../pages/Homepage";
import LeagueList from "../pages/LeagueList";
import Teams from "../pages/Teams";
import Login from "../pages/Login";
import Matches from "../pages/Matches";
import NotFound from "../pages/NotFound";
import Detail from "../Match/Detail";
import UserDetails from "../pages/UserDetails";
import InactiveHandler from "../inactive/InactiveHandler";
import Register2 from "../pages/Register2";
import Coupons from "../pages/Coupons";
import Homepage2 from "../iyzico/Homepage2";
import Nav from "../iyzico/Nav";
import Footer from "../iyzico/Footer";
import Register from "../pages/Register";
import ScrollToTop from "../scrollToTop/ScrollToTop";

function App() {
  return (
    <div>
      {/* <InactiveHandler /> */}
      <Nav />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Homepage2 />} />
        {/* <Route path="leagueList" element={<LeagueList />} /> */}
        {/* <Route path="teams" element={<Teams />} /> */}
        {/* <Route path="leagueList/teams" element={<Teams />} /> */}
        {/* <Route path="matches" element={<Matches />} /> */}
        {/* <Route path="matches/match-details" element={<Detail />} /> */}
        {/* <Route path="login" element={<Login />} /> */}
        {/* <Route path="user_details" element={<UserDetails />} /> */}
        {/* <Route path="register/*" element={<Register2 />} /> */}
        {/* <Route path="coupons" element={<Coupons />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
