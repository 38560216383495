import React, { Component } from "react";
import VerificationInput from "react-verification-input";

export default class VerifyCodeInput extends Component {
  render() {
    return (
      <div className="verify-code-inputs">
        <VerificationInput
          validChars="0-9"
          inputProps={{ inputMode: "numeric" }}
          length={5}
          placeholder=""
        />
      </div>
    );
  }
}
