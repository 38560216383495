import React, { Component } from "react";
import { Link } from "react-router-dom";
import PhoneDropdown from "../dropdowns/PhoneDropdown";
import $ from "jquery";
import { connect } from "react-redux";
import initialState from "../../redux/reducers/initialState";
import { bindActionCreators } from "redux";
import MaskedInput from "react-text-mask";
import * as userActions from "../../redux/actions/userActions";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPhone: true,
      form: {
        phone: "",
        mail: "",
        username: "",
        password: "",
        password_again: "",
        use_agreement: false,
        campaign_notice: false,
      },
      wrong: {
        phone: false,
        mail: false,
        username: false,
        password: false,
        password_again: false,
        use_agreement: true,
      },
      terms: {
        mail: {
          term1: false,
        },
        username: {
          term1: false,
          term2: false,
        },
        password: {
          term1: false,
          term2: false,
          term3: false,
        },
        password_again: {
          term1: false,
        },
      },
      focus: {
        phone: false,
        mail: false,
        username: false,
        password: false,
        password_again: false,
      },
    };
  }

  componentDidMount() {
    switch (window.location.hash) {
      case "#email":
        this.setState(() => ({
          isPhone: false,
        }));
        break;
      case "#phone":
        this.setState(() => ({
          isPhone: true,
        }));
        break;
      default:
        this.setState(() => ({
          isPhone: true,
        }));
        break;
    }
  }

  canBeUsedControl(key, value) {
    this.props.actions.canBeUsedControl(key, value);
  }

  changeInput = (e) => {
    $("#phone").val("");
    $("#mail").val("");
    if (!e.currentTarget.classList.contains("active")) {
      this.setState((prevState) => ({
        isPhone: !prevState.isPhone,
      }));
    }
  };

  usernameControl(value) {
    const startsWithNumber = /^[0-9]/.test(value);

    this.setState((prevState) => ({
      terms: {
        ...prevState.terms,
        username: {
          ...prevState.terms.username,
          term1: !startsWithNumber && value !== "" ? true : false,
          term2: value.length >= 6 ? true : false,
        },
      },
    }));
  }

  passwordControl(value) {
    const hasNumber = /[0-9]/.test(value);
    const hasSpecialChar = /[^A-Za-z0-9]/.test(value);
    const hasRepeatedly = /(.)\1/.test(value);

    this.setState((prevState) => ({
      terms: {
        ...prevState.terms,
        password: {
          ...prevState.terms.password,
          term1: value.length >= 8 ? true : false,
          term2: hasNumber && hasSpecialChar ? true : false,
          term3: !hasRepeatedly && value !== "" ? true : false,
        },
      },
    }));
  }

  passwordAgainControl(value) {
    this.setState((prevState) => ({
      terms: {
        ...prevState.terms,
        password_again: {
          ...prevState.terms.password,
          term1:
            this.state.form.password === value && value !== "" ? true : false,
        },
      },
    }));
  }

  checkedControl = () => {
    if (this.state.form.use_agreement) {
      this.setState((prevState) => ({
        wrong: {
          ...prevState.wrong,
          use_agreement: true,
        },
      }));
      return true;
    } else {
      this.setState((prevState) => ({
        wrong: {
          ...prevState.wrong,
          use_agreement: false,
        },
      }));
      return false;
    }
  };

  handleChange = (e) => {
    this.setState(
      (prevState) => ({
        form: {
          ...prevState.form,
          [e.target.name]: e.target.value,
        },
      }),
      () => {
        switch (e.target.name) {
          case "username":
            this.usernameControl(e.target.value);
            break;
          case "password":
            this.passwordControl(e.target.value);
            this.passwordAgainControl();
            break;
          case "password_again":
            this.passwordAgainControl(e.target.value);
            break;
          default:
            break;
        }
      }
    );
  };

  handleCheckbox = (e) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [e.target.name]: e.target.checked,
      },
    }));
  };

  handleFocus = (e) => {
    switch (e.currentTarget.id) {
      case "mail":
        this.setState({
          focus: {
            mail: true,
          },
        });
        break;
      case "username":
        this.setState({
          focus: {
            username: true,
          },
        });
        break;
      case "password":
        this.setState({
          focus: {
            password: true,
          },
        });
        break;
      case "password-again":
        this.setState({
          focus: {
            password_again: true,
          },
        });
        break;
      default:
        this.setState({
          focus: {
            mail: false,
            username: false,
            password: false,
            password_again: false,
          },
        });
        break;
    }
  };

  handleBlur = (e) => {
    this.setState({
      focus: {
        mail: false,
        username: false,
        password: false,
        password_again: false,
      },
    });

    switch (e.currentTarget.id) {
      case "phone":
        console.log(this.props.selectedPhoneCode);
        break;
      case "mail":
        this.canBeUsedControl("email", this.state.form.mail);
        break;
      case "username":
        this.canBeUsedControl("username", this.state.form.username);
        this.setState((prevState) => ({
          wrong: {
            ...prevState.wrong,
            username:
              this.state.terms.username.term1 && this.state.terms.username.term2
                ? false
                : true,
          },
        }));
        break;
      case "password":
        this.setState((prevState) => ({
          wrong: {
            ...prevState.wrong,
            password:
              this.state.terms.password.term1 &&
              this.state.terms.password.term2 &&
              this.state.terms.password.term3
                ? false
                : true,
          },
        }));
        break;
      case "password-again":
        this.setState((prevState) => ({
          wrong: {
            ...prevState.wrong,
            password_again: this.state.terms.password_again.term1
              ? false
              : true,
          },
        }));
        break;
      default:
        break;
    }
  };

  send = (e) => {
    e.preventDefault();
    console.log(this.checkedControl());
  };

  render() {
    return (
      <div className="form">
        <div className="third-party-register">
          <Link className="third-party-btns btn-apple">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
            </svg>
            <p className="b-4">Apple ile Kayıt ol</p>
          </Link>
          <Link className="third-party-btns btn-google">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 30">
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M15.5 12.2726V18.0817H23.5727C23.2182 19.9499 22.1545 21.5318 20.559 22.5954L25.4272 26.3727C28.2635 23.7546 29.8999 19.9091 29.8999 15.341C29.8999 14.2774 29.8045 13.2545 29.6272 12.2728L15.5 12.2726Z"
              />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M7.09376 17.8551L5.9958 18.6955L2.10938 21.7228C4.57755 26.6182 9.63627 30 15.4999 30C19.5498 30 22.9452 28.6637 25.427 26.3728L20.5589 22.5955C19.2225 23.4955 17.518 24.041 15.4999 24.041C11.5999 24.041 8.28634 21.4092 7.09989 17.8637L7.09376 17.8551Z"
              />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M2.10897 8.27728C1.0863 10.2954 0.5 12.5727 0.5 14.9999C0.5 17.4271 1.0863 19.7045 2.10897 21.7226C2.10897 21.7361 7.09997 17.8498 7.09997 17.8498C6.79997 16.9498 6.62265 15.9953 6.62265 14.9998C6.62265 14.0042 6.79997 13.0497 7.09997 12.1497L2.10897 8.27728Z"
              />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M15.5002 5.97272C17.7093 5.97272 19.6729 6.73634 21.2411 8.20909L25.5365 3.91369C22.9319 1.48646 19.5502 0 15.5002 0C9.63657 0 4.57755 3.36818 2.10938 8.27728L7.10022 12.15C8.28651 8.60452 11.6002 5.97272 15.5002 5.97272Z"
              />
            </svg>
            <p className="b-4">Google ile Kayıt ol</p>
          </Link>
          <Link className="third-party-btns btn-facebook">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" />
            </svg>
            <p className="b-4">Facebook ile Kayıt ol</p>
          </Link>
        </div>
        <div className="divider">
          <hr />
          <p className="b-2">E posta ile kayıt olun</p>
          <hr />
        </div>
        <form onSubmit={this.send}>
          <div className="mail-username">
            <div className="mail-phone">
              <div className="links">
                <Link
                  to="#phone"
                  className={"b-2 " + (this.state.isPhone ? "active" : "")}
                  onClick={this.changeInput}
                >
                  Telefon Numarası
                </Link>
                <Link
                  to="#email"
                  className={"b-2 " + (this.state.isPhone ? "" : "active")}
                  onClick={this.changeInput}
                >
                  E-posta
                </Link>
              </div>
              <div
                className="phone"
                style={
                  this.state.isPhone
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div className="phone-input">
                  <PhoneDropdown />
                  <MaskedInput
                    mask={[
                      /[1-9]/,
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/,
                    ]}
                    name="phone"
                    id="phone"
                    className="b-1"
                    placeholder="555 333 44 44"
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    value={this.state.form.phone}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div
                className="mail"
                style={
                  this.state.isPhone
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                <input
                  type="text"
                  id="mail"
                  name="mail"
                  className={
                    "b-1 input " +
                    (this.props.canBeUse.email && this.state.form.mail !== ""
                      ? "correct"
                      : this.state.form.mail !== ""
                      ? "wrong"
                      : "")
                  }
                  placeholder="********@gmail.com"
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  value={this.state.form.mail}
                  onChange={this.handleChange}
                />
                <div
                  className={
                    "terms non-select " +
                    (!this.props.canBeUse.email ? "active" : "")
                  }
                >
                  <div className="term">
                    <p className={"b-4 wrong"}>Bu E-posta Kullanılıyor.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="username">
              <div className="label">
                <label htmlFor="username" className="b-2">
                  Kullanıcı Adı
                </label>
                <div className="info non-select">
                  <figure>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 128 512"
                    >
                      <path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
                    </svg>
                  </figure>

                  <div className="modal">
                    <p className="b-4">Bu Alan Değiştirilemez</p>
                  </div>
                </div>
              </div>
              <input
                type="text"
                id="username"
                maxLength="16"
                className={
                  "b-1 input " +
                  (this.state.terms.username.term1 &&
                  this.state.terms.username.term2
                    ? "correct"
                    : this.state.form.username !== ""
                    ? "wrong"
                    : "")
                }
                placeholder="user1234"
                name="username"
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                value={this.state.form.username}
                onChange={this.handleChange}
              />
              <div
                className={
                  "terms non-select " +
                  (this.state.focus.username && this.props.canBeUse.username
                    ? "active"
                    : "")
                }
              >
                <div className="term">
                  <figure>
                    {this.state.terms.username.term1 ? (
                      <svg
                        className="correct"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    ) : (
                      <svg
                        className="xmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                      </svg>
                    )}
                  </figure>
                  <p
                    className={
                      "b-4 " +
                      (this.state.terms.username.term1 ? "correct" : "wrong")
                    }
                  >
                    Sayı İle Başlayamaz.
                  </p>
                </div>
                <div className="term">
                  <figure>
                    {this.state.terms.username.term2 ? (
                      <svg
                        className="correct"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    ) : (
                      <svg
                        className="xmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                      </svg>
                    )}
                  </figure>
                  <p
                    className={
                      "b-4 " +
                      (this.state.terms.username.term2 ? "correct" : "wrong")
                    }
                  >
                    En Az 6 Karakterden Oluşmalıdır
                  </p>
                </div>
              </div>
              <div
                className={
                  "terms non-select " +
                  (!this.props.canBeUse.username ? "active" : "")
                }
              >
                <div className="term">
                  <p className={"b-4 wrong"}>Bu Kullanıcı Adı Kullanılıyor.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="passwords">
            <div className="password">
              <label htmlFor="password" className="b-2">
                Şifre
              </label>
              <input
                type="password"
                id="password"
                className={
                  "b-1 input " +
                  (this.state.terms.password.term1 &&
                  this.state.terms.password.term2 &&
                  this.state.terms.password.term3
                    ? "correct"
                    : this.state.form.password !== ""
                    ? "wrong"
                    : "")
                }
                placeholder="********"
                name="password"
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                value={this.state.form.password}
                onChange={this.handleChange}
              />
              <div
                className={
                  "terms non-select " +
                  (this.state.focus.password ? "active" : "")
                }
              >
                <div className="term">
                  <figure>
                    {this.state.terms.password.term1 ? (
                      <svg
                        className="correct"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    ) : (
                      <svg
                        className="xmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                      </svg>
                    )}
                  </figure>
                  <p
                    className={
                      "b-4 " +
                      (this.state.terms.password.term1 ? "correct" : "wrong")
                    }
                  >
                    En Az 8 Karakter Uzunluğunda Olmalı.
                  </p>
                </div>
                <div className="term">
                  <figure>
                    {this.state.terms.password.term2 ? (
                      <svg
                        className="correct"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    ) : (
                      <svg
                        className="xmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                      </svg>
                    )}
                  </figure>
                  <p
                    className={
                      "b-4 " +
                      (this.state.terms.password.term2 ? "correct" : "wrong")
                    }
                  >
                    Sayı ve Özel Karakter Ekleyin.
                  </p>
                </div>
                <div className="term">
                  <figure>
                    {this.state.terms.password.term3 ? (
                      <svg
                        className="correct"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    ) : (
                      <svg
                        className="xmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                      </svg>
                    )}
                  </figure>
                  <p
                    className={
                      "b-4 " +
                      (this.state.terms.password.term3 ? "correct" : "wrong")
                    }
                  >
                    Aynı Karakterleri Art Arda Kullanmayın.
                  </p>
                </div>
              </div>
            </div>
            <div className="password-again">
              <label htmlFor="password-again" className="b-2">
                Tekrar Şifre
              </label>
              <input
                type="password"
                id="password-again"
                className={
                  "b-1 input " +
                  (this.state.terms.password_again.term1
                    ? "correct"
                    : this.state.form.password_again !== ""
                    ? "wrong"
                    : "")
                }
                placeholder="********"
                name="password_again"
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                value={this.state.form.password_again}
                onChange={this.handleChange}
              />
              <div
                className={
                  "terms non-select " +
                  (this.state.focus.password_again &&
                  this.state.form.password_again !== ""
                    ? "active"
                    : "")
                }
              >
                <div className="term">
                  <p
                    className={"b-4 wrong"}
                    style={
                      this.state.terms.password_again.term1
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    Girdiğiniz Şifreler Eşleşmiyor
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="agreements">
            <div className="agreement">
              <label
                htmlFor="use-agreement"
                className={
                  "custom-checkbox b-3 non-select " +
                  (!this.state.wrong.use_agreement ? "wrong" : "")
                }
              >
                Kullanım Sözleşmesini Kabul Ediyorum.
                <input
                  type="checkbox"
                  name="use_agreement"
                  id="use-agreement"
                  value={this.state.form.use_agreement}
                  onChange={this.handleCheckbox}
                />
                <span className="checkmark">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                  </svg>
                </span>
              </label>
            </div>
            <div className="agreement">
              <label
                htmlFor="campaign-notice"
                className="custom-checkbox b-3 non-select"
              >
                Özel fırsatlar, kampanyalar ve duyurular hakkında bilgilendirme
                almak istiyorum.
                <input
                  type="checkbox"
                  name="campaign_notice"
                  id="campaign-notice"
                />
                <span className="checkmark">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                  </svg>
                </span>
              </label>
            </div>
          </div>

          <button type="submit" className="background-btn">
            <h4 className="h-8">Kayıt Ol</h4>
          </button>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      canBeUsedControl: bindActionCreators(
        userActions.canBeUsedControl,
        dispatch
      ),
    },
  };
}

function mapStateToProps(state) {
  return {
    form: initialState.register,
    canBeUse: state.canBeUsedReducer,
    selectedPhoneCode: state.selectPhoneCodeReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
