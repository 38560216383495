import * as actionTypes from "./actionTypes";
import initialState from "../reducers/initialState";
import alertify from "alertifyjs";

export function matchDetailsSuccess(match) {
  return {
    type: actionTypes.MATCH_DETAILS_SUCCESS,
    payload: match,
  };
}
export function matchDetails(id) {
  return function (dispatch) {
    let url = initialState.url + "/matches/details?match_id=" + id;
    return fetch(url)
      .then((response) => response.json())
      .then((result) => dispatch(matchDetailsSuccess(result)))
      .catch((error) => alertify.error("Api Hatasi" + error));
  };
}

export function getLeagueListSuccess(leagueList) {
  return {
    type: actionTypes.GET_LEAGUE_LIST_SUCCESS,
    payload: leagueList,
  };
}

export function getLeagueList() {
  return async function (dispatch) {
    let url = initialState.url + "/matches/league-list";
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      dispatch(getLeagueListSuccess(result));
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}

export function getLeagueTeamsSuccess(teams) {
  return {
    type: actionTypes.GET_LEAGUE_TEAMS_SUCCESS,
    payload: teams,
  };
}

export function getLeagueTeams(id) {
  return function (dispatch) {
    let url = initialState.url + "/matches/league-teams?id=" + id;
    return fetch(url)
      .then((response) => response.json())
      .then((result) => dispatch(getLeagueTeamsSuccess(result)))
      .catch((error) => alertify.error("Api Hatasi" + error));
  };
}

export function getTeamSuccess(team) {
  return {
    type: actionTypes.GET_TEAMS_SUCCESS,
    payload: team,
  };
}

export function getTeam() {
  return function (dispatch) {
    let url = initialState.url + "/matches/teams";
    return fetch(url)
      .then((response) => response.json())
      .then((result) => dispatch(getTeamSuccess(result)))
      .catch((error) => alertify.error("Api Hatasi" + error));
  };
}

export function getUpcomingMatchesSuccess(match) {
  return {
    type: actionTypes.GET_UPCOMING_MATCHES_SUCCESS,
    payload: match,
  };
}

export function getUpcomingMatches() {
  return function (dispatch) {
    let url = initialState.url + "/matches/upcoming-matches";
    return fetch(url)
      .then((response) => response.json())
      .then((result) => dispatch(getUpcomingMatchesSuccess(result)))
      .catch((error) => alertify.error("Api Hatasi" + error));
  };
}

export function getWeeklyMatchesSuccess(match) {
  return {
    type: actionTypes.GET_WEEKLY_MATCHES_SUCCESS,
    payload: match,
  };
}

export function getWeeklyMatches(id) {
  return function (dispatch) {
    let url = initialState.url + "/matches/weekly-matches?week=" + id;
    return fetch(url)
      .then((response) => response.json())
      .then((result) => dispatch(getUpcomingMatchesSuccess(result)))
      .catch((error) => alertify.error("Api Hatasi" + error));
  };
}
