import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class NotFound extends Component {
  render() {
    return (
      <div className="coming-soon">
        <h1 className="h-1">Yakında Gelecek...</h1>
        <p className="h-5">
          Yeni web sitemiz üzerinde çalışıyoruz. Çok yakında sizlerle.
        </p>
        <Link
          to="mailto:info@birmilyondabir.com"
          target="_blank"
          className="outline-btn"
        >
          İletişime Geçin
        </Link>
      </div>
    );
  }
}
