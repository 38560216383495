import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as couponsActions from "../../redux/actions/couponsActions";

class Coupons extends Component {
  componentDidMount() {
    this.props.actions.getCoupons(localStorage.getItem("token"));
  }

  renderLoading() {
    return (
      <div>
        <p>Yükleniyor...</p>
        <p>Bekleyiniz...</p>
      </div>
    );
  }

  renderSuccess() {
    return (
      <div>
        <h1>Coupons - {this.props.coupons.length}</h1>
        {this.props.coupons.map((coupon) => (
          <p key={coupon.id}>{coupon.id}</p>
        ))}
      </div>
    );
  }

  render() {
    if (this.props.coupons.length === 0) return this.renderLoading();

    return this.renderSuccess();
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCoupons: bindActionCreators(couponsActions.getCoupons, dispatch),
    },
  };
}

function mapStateToProps(state) {
  return {
    coupons: state.getCouponsReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Coupons);
