import * as actionTypes from "../actions/actionTypes";
import initialState from "./initialState";

export default function userInactiveReducer(
  state = initialState.isActive,
  action
) {
  switch (action.type) {
    case actionTypes.USER_INACTIVE:
      return {
        ...state,
        isActive: false,
      };
    default:
      return state;
  }
}
