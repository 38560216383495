import * as actionTypes from "./actionTypes";
import initialState from "../reducers/initialState";
import alertify from "alertifyjs";

export function userInactive() {
  return {
    type: actionTypes.USER_INACTIVE,
  };
}

export function userDetailsSuccess(user) {
  return {
    type: actionTypes.GET_USER_DETAILS_SUCCESS,
    payload: user,
  };
}

export function userDetails(token) {
  return function (dispatch) {
    let url = initialState.url + "/users/details";
    return fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        // Authorization:
        //   "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywidXNlcm5hbWUiOiJBYmR1bGxhaF9FbmVzIiwiZW1haWwiOiJrYXJhcGluYXJhYmR1bGxhaGVuZXNAZ21haWwuY29tIiwicGhvbmVfbnVtYmVyIjoiNTU1NSIsInBvaW50cyI6MzUwLCJjb3Vwb25zX3JpZ2h0Ijo1LCJleHAiOjE3MzQ3OTcyMjQsImlhdCI6MTcyOTYxMzIyNH0.V35zjMBuRTn6SHjFx2RdXlnV8gTCFK7_u6rZdxkJuRU",
        "Content-Type": "application/json",
      },
    })
      .then((respose) => respose.json())
      .then((result) => {
        dispatch(userDetailsSuccess(result));
      })
      .catch((error) => alertify.error("Api Hatasi" + error));
  };
}

export function loginSuccess(token) {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: token,
  };
}

export function loginFailure(error) {
  return {
    type: actionTypes.LOGIN_FAILURE,
    payload: error,
  };
}

export function login(username, password) {
  return function (dispatch) {
    let url = initialState.url + "/users/login";
    const data = {
      username: username,
      password: password,
    };
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Kullanıcı Bulunamadı");
          } else if (response.status === 401) {
            throw new Error("Şifre Yanlış");
          } else {
            throw new Error(
              "Bir Hata Meydana Geldi. Daha Sonra Tekrar Deneyiniz."
            );
          }
        }
        return response.json();
      })
      .then((result) => {
        if (
          result !== "Kullanıcı Bulunamadı" &&
          result !== "Şifre Yanlış" &&
          result !== "Bir Hata Meydana Geldi. Daha Sonra Tekrar Deneyiniz."
        ) {
          localStorage.setItem("token", result);
          console.log(result);
        }
        dispatch(loginSuccess(result));
      })
      .catch((error) => dispatch(loginFailure(error.message)));
  };
}

export function registerSuccess(token) {
  return {
    type: actionTypes.REGISTER_SUCCESS,
    payload: token,
  };
}

export function registerFailure(error) {
  return {
    type: actionTypes.REGISTER_FAILURE,
    payload: error,
  };
}

export function register(
  username,
  email,
  phone_no,
  pass,
  date_of_birth,
  tc,
  gender
) {
  return function (dispatch) {
    let url = initialState.url + "/users/register";

    const dateReverse = date_of_birth.split("-").reverse().join(".");

    const data = {
      username: username,
      email: email,
      phone_number: phone_no,
      password: pass,
      date_of_birth: dateReverse,
      tckn: tc,
      gender: gender,
    };

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Bir Hata Meydana Geldi: " + response.statusText);
        }

        return response.json();
      })
      .then((result) => {
        if (
          result !== "Bir Hata Meydana Geldi: Conflict" &&
          result !== "Bir Hata Meydana Geldi: Internal Server Error"
        ) {
          localStorage.setItem("token", result);
          console.log(result);
        }
        dispatch(registerSuccess(result));
      })
      .catch((error) => dispatch(registerFailure(error.message)));
  };
}

////

export function selectLeague(league) {
  return {
    type: actionTypes.SELECT_LEAGUE,
    payload: league,
  };
}

export function selectTeam(team) {
  return {
    type: actionTypes.SELECT_TEAM,
    payload: team,
  };
}

////

export function phoneControl(use) {
  return {
    type: actionTypes.PHONE_CONTROL,
    payload: use,
  };
}

export function emailControl(use) {
  return {
    type: actionTypes.EMAIL_CONTROL,
    payload: use,
  };
}

export function usernameControl(use) {
  return {
    type: actionTypes.USERNAME_CONTROL,
    payload: use,
  };
}

export function canBeUsedControl(key, value) {
  return async function (dispatch) {
    let url = initialState.url + "/users/is-available?" + key + "=" + value;
    try {
      const response = await fetch(url);
      if (!response.ok && response.status === 409) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      switch (key) {
        case "phone_number":
          dispatch(phoneControl(result));
          break;
        case "email":
          dispatch(emailControl(result));
          break;
        case "username":
          dispatch(usernameControl(result));
          break;

        default:
          break;
      }
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}
