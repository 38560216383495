import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../redux/actions/userActions";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      phone_no: "",
      pass: "",
      date_of_birth: "",
      tc: "",
      gender: null,
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  register = (e) => {
    e.preventDefault();

    this.props.actions.register(
      this.state.username,
      this.state.email,
      this.state.phone_no,
      this.state.pass,
      this.state.date_of_birth,
      this.state.tc,
      parseInt(this.state.gender)
    );
  };

  render() {
    return (
      <div>
        <h1>Register</h1>
        <form onSubmit={this.register}>
          <div>
            <label>username</label>
            <input
              type="text"
              name="username"
              value={this.username}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <label>email</label>
            <input
              type="email"
              name="email"
              value={this.email}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <label>Phone Number</label>
            <input
              type="text"
              name="phone_no"
              value={this.phone_no}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <label>password</label>
            <input
              type="text"
              name="pass"
              value={this.pass}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <label>birth Date</label>
            <input
              type="date"
              name="date_of_birth"
              value={this.date_of_birth}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <label>TC</label>
            <input
              type="text"
              name="tc"
              value={this.tc}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <label>Gender</label>
            <input
              type="number"
              name="gender"
              value={this.gender}
              onChange={this.handleChange}
            />
          </div>
          <button type="submit">Send</button>
        </form>
        <p>{this.props.token}</p>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      register: bindActionCreators(userActions.register, dispatch),
    },
  };
}

function mapStateToProps(state) {
  return {
    token: state.loginReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
