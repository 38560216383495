import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../redux/actions/userActions";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  login = (e) => {
    e.preventDefault();

    this.props.actions.login(this.state.username, this.state.password);
  };

  render() {
    return (
      <div>
        <h1>Login</h1>
        <form onSubmit={this.login}>
          <div>
            <label>Name:</label>
            <input
              type="text"
              name="username"
              value={this.username}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <label>pass:</label>
            <input
              type="password"
              name="password"
              value={this.password}
              onChange={this.handleChange}
            />
          </div>
          <button type="submit">Send</button>
        </form>
        <p>{this.props.token}</p>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      login: bindActionCreators(userActions.login, dispatch),
    },
  };
}

function mapStateToProps(state) {
  return {
    token: state.loginReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
