import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import initialState from "../../redux/reducers/initialState";
import * as userActions from "../../redux/actions/userActions";
import MiniPreload from "../preload/MiniPreload";

class TeamsDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    console.log(this.props.teams);
  }

  toggleDropdown = (e) => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleOptionClick = (option) => {
    this.props.actions.selectTeam(option);
    this.setState({
      isOpen: false,
    });
  };

  teams() {
    return this.props.teams.map((team) => (
      <li
        className="option"
        key={team.id}
        onClick={() => this.handleOptionClick(team)}
      >
        <div className="img-drop">
          <img
            className="non-select"
            src={initialState.url + "/media/" + team.logo}
            alt={team.code}
          />
        </div>
        <div className="txt">
          <h4 className="h-8 non-select">{team.name}</h4>
          <p className="b-4 non-select">{team.code}</p>
        </div>
      </li>
    ));
  }

  render() {
    return (
      <div className="teams">
        <p className="b-3">Sevdiğiniz Takımı Seçiniz</p>
        <div
          className={
            "custom-dropdown" +
            (this.props.teams.length === 0 ? " disabled" : "")
          }
        >
          <div className="disabled"></div>
          <div
            className={`select-trigger ${this.state.isOpen ? "open" : ""}`}
            id="teams"
            onClick={(e) => this.toggleDropdown(e)}
          >
            <div className="content-drop">
              <div className="img-drop">
                <img
                  className="non-select"
                  src={
                    initialState.url + "/media/" + this.props.selectedTeam.logo
                  }
                  alt={this.props.selectedTeam.id}
                />
              </div>
              <div className="txt">
                <h4 className="h-8 non-select">
                  {this.props.selectedTeam.name}
                </h4>
                <p className="b-4 non-select">{this.props.selectedTeam.code}</p>
              </div>
            </div>
            <div className={`arrow ${this.state.isOpen ? "open" : ""}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
              </svg>
            </div>
          </div>
          {this.state.isOpen && (
            <ul className="options">
              {this.props.teams.length === 0 ? <MiniPreload /> : this.teams()}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    teams: state.teamsListReducer,
    selectedTeam: state.selectTeamReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      selectTeam: bindActionCreators(userActions.selectTeam, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDropdown);
