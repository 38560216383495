import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import * as matchActions from "../../redux/actions/matchActions";

class Matches extends Component {
  componentDidMount() {
    this.props.actions.getMatches();
  }
  matchDetails(id) {
    this.props.actions.matchDetails(id);
  }
  render() {
    return (
      <div>
        <h1>Matches</h1>
        {this.props.matches.map((match) => (
          <div key={match.match_id}>
            <Link
              to={"match-details?match_id=" + match.match_id}
              onClick={() => this.matchDetails(match.match_id)}
            >
              {match.home} - {match.away}
            </Link>
            <br />
          </div>
        ))}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getMatches: bindActionCreators(matchActions.getUpcomingMatches, dispatch),
      // getMatches: bindActionCreators(matchActions.getWeeklyMatches, dispatch),
      matchDetails: bindActionCreators(matchActions.matchDetails, dispatch),
    },
  };
}

function mapStateToProps(state) {
  return {
    matches: state.matchesListReducer,
    match: state.matchDetailReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Matches);
