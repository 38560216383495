import React, { Component } from "react";
import { connect } from "react-redux";
import * as userActions from "../../redux/actions/userActions";

class InactiveHandler extends Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.resetTimer = this.resetTimer.bind(this);
  }

  componentDidMount() {
    // Kullanıcı etkinliklerini dinlemeye başla
    window.addEventListener("mousemove", this.resetTimer);
    window.addEventListener("keydown", this.resetTimer);

    // Zamanlayıcıyı başlat
    this.resetTimer();
  }

  componentWillUnmount() {
    // Etkinlik dinleyicilerini temizle
    clearTimeout(this.timeout);
    window.removeEventListener("mousemove", this.resetTimer);
    window.removeEventListener("keydown", this.resetTimer);
  }

  resetTimer() {
    // Eski zamanlayıcıyı temizle
    clearTimeout(this.timeout);

    // Zamanlayıcıyı tekrar başlat (örnek olarak 5 saniye)
    this.timeout = setTimeout(() => {
      // Redux action tetikleme
      this.props.dispatch(userActions.userInactive());
      window.location.reload();
      localStorage.setItem("token", "");
    }, 1800000); // 30 dk inaktif kalındığında işlem tetiklenecek
  }

  render() {
    return <div className="inactiveHandler"></div>;
  }
}

export default connect()(InactiveHandler);
