// STATICS
export const GET_PHONE_CODES = "GET_PHONE_CODES";
export const GET_PHONE_CODES_SUCCESS = "GET_PHONE_CODES_SUCCESS";
//
export const SELECT_PHONE_CODE = "SELECT_PHONE_CODE";

// COUPONS
export const GET_COUPONS = "GET_COUPONS";
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS";
export const GET_COUPONS_DETAILS = "GET_COUPONS_DETAILS";
export const GET_COUPONS_DETAILS_SUCCESS = "GET_COUPONS_DETAILS_SUCCESS";

// MATCHES
export const MATCH_DETAILS = "MATCH_DETAILS";
export const MATCH_DETAILS_SUCCESS = "MATCH_DETAILS_SUCCESS";
export const GET_LEAGUE_LIST = "GET_LEAGUE_LIST";
export const GET_LEAGUE_LIST_SUCCESS = "GET_LEAGUE_LIST_SUCCESS";
export const GET_LEAGUE_TEAMS = "GET_LEAGUE_TEAMS";
export const GET_LEAGUE_TEAMS_SUCCESS = "GET_LEAGUE_TEAMS_SUCCESS";
export const GET_TEAMS = "GET_TEAMS";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_UPCOMING_MATCHES = "GET_UPCOMING_MATCHES";
export const GET_UPCOMING_MATCHES_SUCCESS = "GET_UPCOMING_MATCHES_SUCCESS";
export const GET_WEEKLY_MATCHES = "GET_WEEKLY_MATCHES";
export const GET_WEEKLY_MATCHES_SUCCESS = "GET_WEEKLY_MATCHES_SUCCESS";

// USERS
export const USER_INACTIVE = "USER_INACTIVE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
//
export const SELECT_LEAGUE = "SELECT_LEAGUE";
export const SELECT_TEAM = "SELECT_TEAM";
//
export const PHONE_CONTROL = "PHONE_CONTROL";
export const EMAIL_CONTROL = "EMAIL_CONTROL";
export const USERNAME_CONTROL = "USERNAME_CONTROL";
