import { combineReducers } from "redux";
import leagueListReducer from "./leagueListReducer";
import teamsListReducer from "./teamsListReducer";
import loginReducer from "./loginReducer";
import matchesListReducer from "./matchesListReducer";
import matchDetailReducer from "./matchDetailReducer";
import getUserDetailsReducer from "./getUserDetailsReducer";
import userInactiveReducer from "./userInactiveReducer";
import getCouponsReducer from "./getCouponsReducer";
import selectLeagueReducer from "./selectLeagueReducer";
import selectTeamReducer from "./selectTeamReducer";
import getPhoneCodesReducer from "./getPhoneCodesReducer";
import selectPhoneCodeReducer from "./selectPhoneCodeReducer";
import canBeUsedReducer from "./canBeUsedReducer";

const rootReducer = combineReducers({
  leagueListReducer,
  teamsListReducer,
  matchesListReducer,
  matchDetailReducer,
  loginReducer,
  getUserDetailsReducer,
  userInactiveReducer,
  getCouponsReducer,
  selectLeagueReducer,
  selectTeamReducer,
  selectPhoneCodeReducer,
  getPhoneCodesReducer,
  canBeUsedReducer,
});

export default rootReducer;
