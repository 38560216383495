import * as actionTypes from "../actions/actionTypes";
import initialState from "./initialState";

export default function getCouponsReducer(
  state = initialState.coupons,
  action
) {
  switch (action.type) {
    // case actionTypes.SET_LOADING:
    //   return action.payload;
    case actionTypes.GET_COUPONS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
